<template>
  <div class="fs-container">
    <div class="fs-section fs-map">
      <div class="fs-inr map-inr">
        <div class="fs-title">
          <h1>{{ $t('Common.ITEL agent system') }}</h1>
        </div>
        <div class="fs-box">
          <div class="agencys">
            <div class="agency-location">
              <div class="small-title">{{ $t('Common.Find a store') }}</div>
              <div class="agency-select">
                <span class="small-title">Tỉnh/TP</span>
                <div class="item-select mb-2">
                  <Select2 v-model="CityId" :options="Cities" :settings="{allowClear:true}"/>
                </div>
                <span class="small-title">Quận/Huyện</span>
                <div class="item-select">
                  <Select2 v-model="DistrictId" :options="Districts" :settings="{allowClear:true}"/>
                </div>
              </div>
            </div>

            <div class="agency-wrap">
              <div class="small-title" v-if="CityId!=0 && Stores.length>0">
                {{Cities.find(i=>i.id==CityId).text}} {{ $t('Common.has') }} {{Stores.filter(i=> i.CityId==CityId ).length}} {{ $t('Common.store') }}
              </div>
              <div class="agency-outer">
                <div class="agency-scroll">
                  <div class="list-agency" v-if="CityId>0">
                    <template v-for="(store,stt) in Stores.filter(i=> i.CityId==CityId && (DistrictId==0 || DistrictId==null || i.DistrictId==DistrictId) )">
                      <a class="agency" data-index="0" @click="selectStore(store.Lat, store.Long)" :key="store.name">
                        <div class="fs-txt">
                          <h3>{{stt+1}}.{{store.Name}}</h3>
                          <p>{{store.Address}}</p>
                          <p>ĐT: {{store.Phone}}</p>
                          <div class="link-agency">
                            <a target="_blank"
                              :href="'http://maps.google.com/maps/dir/?api=1&destination='+store.Lat+','+store.Long+'&travelmode=driving'"
                              :title="$t('Common.Direct')">{{ $t('Common.Direct') }}</a>
                          </div>
                        </div>
                      </a>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Map4dMap @onMapReady="onMapReady" :options="options1" :id="id" version="2.4"
                    accessKey="73c38375820e9ce61a448b388664239e"/>
          <div class="map" v-if="Stores.length>0">
            <!--<agm-map [latitude]="currentPosition.lat" [longitude]="currentPosition.lng" [zoom]="numberZoom"
              id="map-agency" (mapReady)="mapReady($event)">
              <agm-marker *ngFor="let position of storesFilter" [latitude]="position.Lat" [longitude]="position.Lng">
              </agm-marker>
            </agm-map>-->
<!--            <google-map-->
<!--              :center="{lat:Lat, lng:Lng}"-->
<!--              :zoom="14"-->
<!--              map-type-id="roadmap"-->
<!--              id="map-agency"-->
<!--              v-if="!isLoading">-->
<!--              <google-map-marker-->
<!--                :key="index"-->
<!--                v-for="(m, index) in Stores.filter(i=> i.CityId==CityId)"-->
<!--                :position="m.position"-->
<!--                :clickable="true"-->
<!--                @click="toggleInfoWindow(m)"-->
<!--                :title="m.Address"-->
<!--                :icon="require('@/assets/images/ggmaplogo.png')"-->
<!--              />-->
<!--              <google-map-infowindow-->
<!--                  :position="infoWindowContext.position"-->
<!--                  :show.sync="showInfo"-->
<!--                  :options="{maxWidth: 300}"-->
<!--                  @info-window-clicked="infoClicked"-->
<!--              >-->
<!--                <h4>{{infoWindowContext.Name}}</h4>-->
<!--                <p>{{infoWindowContext.Address}}</p>-->
<!--              </google-map-infowindow>-->
<!--            </google-map>-->
          </div>
        </div>
      </div>
    </div>
    <div class="fs-section fs-question">
      <div class="fs-inr question-inr" v-if="ConfigData.length>0">
        <div class="fs-txt">
          <h2>{{ $t('Common.NeedHelp') }}</h2>
          <p>{{ $t('Common.SendIdeal') }}</p>
          <div class="fs-buts">
            <a :href="'tel:'+ConfigData.find(i=>i.DataKey=='PhoneWebsite').Value" class="fs-but" :title="$t('Common.CallItel')">{{
              $t('Common.CallItel') }}</a>
            <a :href="'mailto:'+ConfigData.find(i=>i.DataKey=='EmailWebsite').Value" class="fs-but" :title="$t('Common.EmailItel')">
              {{ $t('Common.EmailItel') }}
            </a>
          </div>
        </div>
        <div class="fs-pic">
          <img class="cmPic" src="@/assets/images/ho-tro/img-any-question.png"
            :alt="$t('ServicePage.CHANGE SIM NUMBER')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Navigation, Pagination } from 'swiper'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import useJwt from "@/auth/jwt/useJwt";
import EventBus from "@/global-components";
import { isMobile } from 'mobile-device-detect';
import Select2 from 'v-select2-component';
import Vue from 'vue'
import VueGoogleMap from 'vuejs-google-maps'
import 'vuejs-google-maps/dist/vuejs-google-maps.css'
 import Map4dMap from "@/components/Map4dMap.vue";
Vue.use(VueGoogleMap, {
  load: {
    apiKey: 'AIzaSyAFeu4P5d_2eerEwOc6UkzkXA1OuTdbGj8',
    libraries: ['places'], // This is required if you use the Autocomplete plugin
  },
})

// Import Swiper styles
import 'swiper/swiper-bundle.css'
import {required} from "@core/utils/validations/validations";
import imglogo from "@/assets/images/ggmaplogo.png";
SwiperCore.use([Navigation, Pagination])
function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images['assets/images/ve-itel/'+item.replace('./', '')] = r(item);    
  });
  return images;
}
const images = importAll(require.context('@/assets/images/ve-itel/', false, /.(png)$/));


export default {
  computed: {
    required() {
      return required
    }
  },
  components: {
    Swiper, SwiperSlide,Select2,VueGoogleMap,Map4dMap
  },
  data() {
    return {
      isLoading:false,
      staticPageDetail:{},
      ConfigData:[],
      Cities:[],
      Districts:[],
      Lat:21.014154361725378,
      Lng:105.77638862325031,
      Stores:[],
      CityId:0,
      DistrictId:0,
      videoId:'',
      modalClass:'',
      Slug: '',
      showInfo: false,
      infoWindowContext: {
        position: {
          lat: 21.028511,
          lng: 105.804817
        }
      },
      options1: {
        center: {lat: 21.014154361725378, lng: 105.77638862325031},
        zoom: 17,
        controls: false
      },
      id: "map2",
      // EX: declare reactive value in map - should not be used
      circle: null,
      mapMarkers: [],
      map: null,
    }
  },
  watch: {
    $route(to, from) {
      this.fetch();
    },
    CityId: function(i,v){
      if (this.CityId>0){
        useJwt.post('getMasterData/Districts',{columnFilters:{CityId:this.CityId},sort:[],page:1,pageSize:1000,lang:this.$l}).then(response =>{
          this.Districts = response.data.result;
          console.log("Districts",response.data.result)
        })
        var x = this.Stores.filter(i=> i.CityId==this.CityId );
        this.isLoading=true;
        console.log(x);
        if (x.length>0){
          this.Lat = x[0].Lat*1;
          this.Lng = x[0].Long*1;
          this.isLoading=false;
        }
      }
    }
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
  mounted(){
    this.Slug = this.$route.params.Slug;
    this.setAnimation();
    var t_ = this;
    EventBus.$on('CHANGE_LANG', function (payLoad) {
      t_.fetch();
    });
  },
  methods: {
    async onMapReady(map, id) {
      //TODO: Map interaction from here
      await this.fetch();
      let map4d = window.map4d //remove if disable no-undef from eslint

      let centerMap = map.getCamera().getTarget()
      // More markers
      for (let i = 0; i < this.Stores.length; i++) {
        console.log(parseFloat( this.Stores[i].Lat) ,parseFloat( this.Stores[i].Long ))
        let imglogo = require('@/assets/images/ggmaplogo.png')
        const marker = new map4d.Marker({
          position: {lat: this.Stores[i].Lat * 1, lng: this.Stores[i].Long * 1},
          anchor: [0.5, 1.0],
          draggable: false,
          iconView: `<img src=${imglogo}>`,
        });
        marker.setMap(map)
        this.mapMarkers.push(marker);
      }
      this.map = map;
    },
    toggleInfoWindow(context) {
      console.log(this.infoWindowContext);
      this.infoWindowContext = context
      console.log(this.infoWindowContext);
      this.showInfo = true
    },
    infoClicked(context) {
      console.log(context)
    },
    Img(pic){
      return images[pic];
    },
    selectStore(lat,lng){
      this.isLoading=true;
      this.map.moveCamera({target: {lng:parseFloat(lng),lat:parseFloat(lat)}})
      console.log("cam positon", this.map._mapView.mapRenderer.mapState.cameraPosition.target )
      this.isLoading = true;
      console.log('check map', this.map.getCamera().getTarget())
      this.isLoading = false;
    },
    async fetch(){
      this.loading(true);
      await useJwt.post('getMasterData/Stores',{columnFilters:{},sort:[],page:1,pageSize:1000,lang:this.$l}).then(response =>{
        this.Stores = response.data.result;
        this.Stores.forEach(i=>{
          i.position={lat:i.Lat*1,lng:i.Long*1}
        })
      }).catch(err=>{
        this.$toast.error('Có lỗi trong khi lấy dữ liệu, Quý khách vui lòng thử lại!', {icon:true, closeButton: "button"})
      })
      await useJwt.post('getMasterData/Cities',{columnFilters:{},sort:[],page:1,pageSize:1000,lang:this.$l}).then(response =>{
        this.Cities = response.data.result;
        this.CityId = this.Cities[17].id;
      }).catch(err=>{
        this.$toast.error('Có lỗi trong khi lấy dữ liệu, Quý khách vui lòng thử lại!', {icon:true, closeButton: "button"})
      })
      await useJwt.post('getMasterData/ConfigData',{columnFilters:{},sort:[],page:1,pageSize:1000,lang:this.$l}).then(response =>{
        this.ConfigData = response.data.result;
      }).catch(err=>{
        this.$toast.error('Có lỗi trong khi lấy dữ liệu, Quý khách vui lòng thử lại!', {icon:true, closeButton: "button"})
      })
      this.loading(false);
    },
    openVideo(url){
      this.modalClass = 'modal__open';
      this.videoId = 'https://www.youtube.com/embed/' + url + '?rel=0&amp;autoplay=1&amp;playsinline=1';
    },
    closeVideo(){
      this.videoId='';
      this.modalClass = '';
    },
    setAnimation() {
      const windowH = window.screen.height;
      const headH = document.querySelector('.fs-header').clientHeight;
      [].slice.call(document.querySelectorAll('.fs-banner, .choose-pic, .choose-txt, .pack-txt, .pack-info, .i-news-infos, .video-box, .support-pic, .support-txt, .like-pic, .welcome-inr .fs-txt, .welcome-inr .fs-pic, .why-inr .fs-pic, .item-reseaon, .hand-pic, .hand-txt,.about-item, .block-title, .mission-item, .value-item, .promotion-video-box, .vision-inr, .fs-distributions .fs-inr')).forEach((elm) => {
        const style = window.getComputedStyle(elm, null);
        const elmH = style.getPropertyValue('height');
        const inputT = windowH - elm.getBoundingClientRect().top;
        if (inputT > 0 && inputT < (windowH + parseInt(elmH, 10) - headH / 2)) {
          elm.classList.add('fs-ani');
        } else {
          elm.classList.remove('fs-ani');
        }
      });
    }
  }
}
</script>
